import uikit from "./uikit.js"
import components from "./components.js"
import Headroom from "headroom.js"
import Swiper, {
  EffectCreative,
  EffectCoverflow,
  Autoplay,
  Thumbs,
  Navigation,
  Pagination,
} from "swiper"
import "swiper/css"
import "swiper/css/navigation"
import "swiper/css/pagination"
import localforage from "localforage"
import { CountUp } from "countup.js"
import anime from "animejs/lib/anime.es.js"
import tweenMax from "./tweenMax.min"
import "bootstrap/dist/css/bootstrap-grid.min.css"
import "bootstrap/dist/css/bootstrap-utilities.min.css"

uikit()
components()
function uikitPageDropdown() {
  $(".js-uikit-pages-dropdown").on("click", (e) => {
    $(".front-ui-dropdown").toggleClass("active")
  })
}
uikitPageDropdown()

const preloader = document.querySelector(".preloader")
if (preloader) {
  setTimeout(() => {
    preloader.classList.add("disabled")
  }, 310)
}

if (window.matchMedia("screen and (min-width: 769px)").matches) {
  var html = document.documentElement
  var body = document.body

  var scroller = {
    target: document.querySelector("#scroll-container"),
    ease: 0.06,
    endY: 0,
    y: 0,
    resizeRequest: 1,
    scrollRequest: 0,
  }

  var requestId = null

  TweenLite.set(scroller.target, {
    rotation: 0.01,
    force3D: true,
  })

  window.addEventListener("load", onLoad)

  function onLoad() {
    updateScroller()
    window.focus()
    window.addEventListener("resize", onResize)
    document.addEventListener("scroll", onScroll)
  }

  function updateScroller() {
    var resized = scroller.resizeRequest > 0

    if (resized) {
      var height = scroller.target.clientHeight
      body.style.height = height + "px"
      scroller.resizeRequest = 0
    }

    var scrollY = window.pageYOffset || html.scrollTop || body.scrollTop || 0

    scroller.endY = scrollY
    scroller.y += (scrollY - scroller.y) * scroller.ease

    if (Math.abs(scrollY - scroller.y) < 0.05 || resized) {
      scroller.y = scrollY
      scroller.scrollRequest = 0
    }

    TweenLite.set(scroller.target, {
      y: -scroller.y,
    })

    requestId =
      scroller.scrollRequest > 0 ? requestAnimationFrame(updateScroller) : null
  }

  function onScroll() {
    scroller.scrollRequest++
    if (!requestId) {
      requestId = requestAnimationFrame(updateScroller)
    }
  }

  function onResize() {
    scroller.resizeRequest++
    if (!requestId) {
      requestId = requestAnimationFrame(updateScroller)
    }
  }
}

var app = {
  load() {
    console.log("load")
    const items = document.querySelectorAll(".after-loaded")
    items.forEach((item) => {
      setTimeout(() => {
        item.style.opacity = ""
        item.style.zIndex = ""
      }, 300)
    })
  },
  smoothScrolling() {},
  preloaderAnimation() {
    function introFn() {
      document.querySelector(".js-intro").style.opacity = "1"
      var lineDrawing = anime({
        targets: ".intro path",
        strokeDashoffset: [anime.setDashoffset, 0],
        easing: "linear",
        duration: 3200,
        begin: function (anim) {
          document.querySelector(".js-intro").classList.add("active")

          setTimeout(() => {
            document.querySelectorAll(".intro path").forEach((path) => {
              path.setAttribute("fill", "#fff")
            })
          }, anim.duration)
          setTimeout(() => {
            document.querySelector(".js-intro").style.opacity = "0"
            document.querySelector(".js-intro").classList.remove("active")
          }, anim.duration + 650)
        },

        autoplay: true,
        loop: false,
      })
    }
    var currentDate = new Date()
    localforage.setDriver(localforage.LOCALSTORAGE)
    var lastLoad = localforage.getItem("last-load", function (err, value) {
      if (value == null) {
        introFn()
        // document.querySelector(".preloader").remove()
        localforage.setItem("last-load", currentDate)
      } else {
        localforage.getItem("last-load", function (err, value) {
          lastLoad = new Date(value)
          var hoursDiff = currentDate.getHours() - lastLoad.getHours() >= 1
          if (hoursDiff) {
            document.querySelector(".js-intro").classList.remove("active")
            document.querySelector(".js-intro").remove()
            localforage.setItem("last-load", currentDate)
          } else {
            if (document.querySelector(".js-intro")) {
              document.querySelector(".js-intro").classList.remove("active")
              document.querySelector(".js-intro").remove()
            }
          }
        })
      }
    })
  },
  resized() {
    console.log("resized")
  },
  headRoomFn() {
    const header = document.querySelector(".header")
    if (header) {
      var options = {
        offset: 40,
        tolerance: 2,
        classes: {
          initial: "header",
          pinned: "header--pinned",
          unpinned: "header--unpinned",
          top: "header--top",
          notTop: "header--sticky",
          bottom: "header--bottom",
          notBottom: "header--not-bottom",
          frozen: "header--frozen",
          pinned: "header--pinned",
        },
      }

      var headroom = new Headroom(header, options)
      headroom.init()
    }
  },

  headerLanguageFn() {
    const headerLanguageBtn = document.querySelector(".js-header-language-btn")
    if (headerLanguageBtn) {
      window.addEventListener("click", function (e) {
        if (document.querySelector(".header__language").contains(e.target)) {
          headerLanguageBtn.parentNode.classList.toggle("opened")
        } else {
          headerLanguageBtn.parentNode.classList.remove("opened")
        }
      })
      window.addEventListener("click", function (e) {
        if (
          document.querySelector(".header__language-wrapper").contains(e.target)
        ) {
          document
            .querySelector(".header__language-wrapper")
            .parentNode.classList.add("opened")
        }
      })
    }
  },

  accordionFn() {
    // accordion component
    setTimeout(() => {
      const bodyHeight = document.querySelector("body").offsetHeight
      console.log(bodyHeight)

      $("body").on("click", ".js-accordion-trigger", function () {
        $(this).toggleClass("active")

        const accordionGroup = $(this).closest(".accordion-wrapper")
        const item = $(this).closest(".accordion-item")
        let multipleShow = false

        if (accordionGroup.data("multiple-show") == true) {
          multipleShow = true
        } else {
          $(".js-accordion-trigger").not(this).removeClass("active")
        }
        let content = item.find(".accordion-calc")
        let contentHeight = content.outerHeight(true)
        console.log(contentHeight)
        if (item.hasClass("active") && !$(this).hasClass("force-open")) {
          item.find(".accordion-content").css("height", 0 + "rem")
          item.removeClass("active")
          document.querySelector("body").style.height = bodyHeight + "px"
        } else {
          if (!multipleShow) {
            accordionGroup.children(".accordion-item").removeClass("active")
            accordionGroup
              .children(".accordion-item")
              .find(".accordion-content")
              .css("height", 0 + "rem")
            document.querySelector("body").style.height = bodyHeight + "px"
          }
          item.addClass("active")
          item.find(".accordion-content").css("height", contentHeight)
          document.querySelector("body").style.height =
            bodyHeight + contentHeight + 25 + "px"
        }
      })

      $(".accordion-item.opened .js-accordion-trigger").trigger("click")
    }, 100)
  },

  searchButtonFn() {
    const searchBtn = document.querySelector("#js-search-button")
    const Input = document.querySelector(".header__search-input input")
    if (searchBtn) {
      searchBtn.addEventListener("click", function () {
        this.parentNode.classList.toggle("opened")
        if (this.parentNode.classList.contains("opened")) {
          if (Input.value.length > 3) {
            document
              .querySelector(".header__search-results")
              .classList.add("opened")
          }
        } else {
          document
            .querySelector(".header__search-results")
            .classList.remove("opened")
        }
        Input.addEventListener("keyup", () => {
          if (Input.value.length > 3) {
            document
              .querySelector(".header__search-results")
              .classList.add("opened")
          } else {
            document
              .querySelector(".header__search-results")
              .classList.remove("opened")
          }
        })
      })
    }
  },

  mobileMenuFn() {
    const menuBtn = document.querySelector(".js-open-menu")
    const menu = document.querySelector(".js-menu")
    menuBtn.addEventListener("click", () => {
      menuBtn.classList.toggle("opened")
      menu.classList.toggle("opened")
      document.querySelector("html").classList.toggle("no-scroll")
      document.querySelector(".header").classList.toggle("header--with-menu")
    })
    const headerTitle = document.querySelectorAll(".js-mobile-menu-list-item")
    headerTitle.forEach((el) => {
      el.addEventListener("click", function () {
        const elParent = el.closest(".mobile-menu__list-inner")
        if (elParent.classList.contains("opened")) {
          elParent.classList.remove("opened")
          elParent.querySelector(".mobile-menu__list-dropdown").style.height =
            0 + "px"
        } else {
          document
            .querySelectorAll(".mobile-menu__list-inner.opened")
            .forEach((element) => {
              element.classList.remove("opened")
              element.querySelector(
                ".mobile-menu__list-dropdown"
              ).style.height = 0 + "px"
            })
          elParent.classList.add("opened")
          const listHeight = elParent.querySelector(
            ".js-mobile-menu-dropdown-list"
          ).offsetHeight
          elParent.querySelector(".mobile-menu__list-dropdown").style.height =
            listHeight + "px"
        }
      })
    })
  },

  heroSwiper() {
    const sliderCount = document.querySelector(".js-hero-slider-count")
    const sliderCountLength = document.querySelector(
      ".js-hero-slider-count-length"
    )
    const progressBar = document.querySelector(".hero-progress")
    var mainHeroSwiperThumb = new Swiper(".js-hero-slider-thumb", {
      speed: 800,
      loop: true,
      direction: "vertical",
      effect: "coverflow",
      modules: [EffectCoverflow],
      coverflowEffect: {
        rotate: 0,
        stretch: 0,
        depth: 0,
        modifier: 1,
        slideShadows: false,
      },
    })
    var mainHeroSwiper = new Swiper(".js-hero-slider", {
      speed: 800,
      loop: true,
      effect: "creative",
      creativeEffect: {
        prev: {
          shadow: true,
          opacity: 0,
          translate: [0, "-100%", -400],
        },
        next: {
          opacity: 1,
          translate: [0, "100%", 0],
        },
      },
      noSwiping: true,
      touchRatio: 0,
      simulateTouch: false,
      thumbs: {
        swiper: mainHeroSwiperThumb,
      },
      navigation: {
        nextEl: ".js-hero-slider-next",
        prevEl: ".js-hero-slider-prev",
      },
      modules: [EffectCreative, Navigation, Thumbs],

      on: {
        init: function (e) {
          if (e.realIndex + 1 >= 10) {
            sliderCount.innerHTML = e.realIndex + 1
          } else {
            sliderCount.innerHTML = "0" + (e.realIndex + 1)
          }
          if (e.slides.length >= 10) {
            sliderCountLength.innerHTML = e.slides.length
          } else {
            sliderCountLength.innerHTML = "0" + (e.slides.length - 2)
          }
        },
        slideChange: function (e) {
          if (e.realIndex + 1 >= 10) {
            sliderCount.innerHTML = e.realIndex + 1
          } else {
            sliderCount.innerHTML = "0" + (e.realIndex + 1)
          }
          if (e.slides.length >= 10) {
            sliderCountLength.innerHTML = e.slides.length
          } else {
            sliderCountLength.innerHTML = "0" + (e.slides.length - 2)
          }
        },
      },
    })

    if (progressBar) {
      progressBar.addEventListener("animationend", myEndFunction)

      function myEndFunction() {
        mainHeroSwiper.slideNext()
        progressBar.style.animation = "none"
        void progressBar.offsetWidth
        progressBar.style.animation = null
      }

      mainHeroSwiper.on("slideChange", function () {
        progressBar.style.animation = "none"
        void progressBar.offsetWidth
        progressBar.style.animation = null
      })
    }
  },

  heroCategorySwiper() {
    var heroCategorySlider = new Swiper(".js-hero-category-slider", {
      speed: 800,
      loop: true,
      slidesPerView: 2.4,
      spaceBetween: 40,
      effect: "coverflow",
      modules: [EffectCoverflow, Autoplay],
      coverflowEffect: {
        rotate: 0,
        stretch: 0,
        depth: 0,
        modifier: 1,
        slideShadows: false,
      },

      autoplay: {
        delay: 2500,
      },
      breakpoints: {
        320: {
          slidesPerView: 1.2,
          spaceBetween: 15,
        },
        577: {
          slidesPerView: 1.2,
          spaceBetween: 15,
        },
        769: {
          slidesPerView: 1.8,
          spaceBetween: 20,
        },
        993: {
          slidesPerView: 2.4,
          spaceBetween: 40,
        },
      },
    })
  },

  fieldsSwiper() {
    var fieldsSlider = new Swiper(".js-fields-slider", {
      speed: 800,
      loop: true,
      slidesPerView: 3,
      spaceBetween: 40,
      modules: [EffectCoverflow],
      effect: "coverflow",
      centeredSlides: true,
      coverflowEffect: {
        rotate: 0,
        stretch: 0,
        depth: 0,
        modifier: 1,
        slideShadows: false,
      },
      breakpoints: {
        320: {
          slidesPerView: 1.2,
          spaceBetween: 15,
        },
        577: {
          slidesPerView: 1.6,
          spaceBetween: 20,
        },
        769: {
          slidesPerView: 2.2,
          spaceBetween: 20,
        },
        993: {
          slidesPerView: 2.4,
          spaceBetween: 20,
        },
        1201: {
          slidesPerView: 3,
          spaceBetween: 40,
        },
      },
    })
  },

  seperatorSwiper() {
    const sliderCount = document.querySelector(".js-seperator-slider-count")
    const sliderCountLength = document.querySelector(
      ".js-seperator-slider-count-length"
    )
    var seperatorSlider = new Swiper(".js-seperator-slider", {
      speed: 800,
      loop: true,
      slidesPerView: 1,
      spaceBetween: 0,
      modules: [EffectCoverflow],
      effect: "coverflow",
      noSwiping: true,
      touchRatio: 0,
      simulateTouch: false,
      coverflowEffect: {
        rotate: 0,
        stretch: 0,
        depth: 0,
        modifier: 1,
        slideShadows: false,
      },
      on: {
        init: function (e) {
          if (e.realIndex + 1 >= 10) {
            sliderCount.innerHTML = e.realIndex + 1
          } else {
            sliderCount.innerHTML = "0" + (e.realIndex + 1)
          }
          if (e.slides.length >= 10) {
            sliderCountLength.innerHTML = e.slides.length
          } else {
            sliderCountLength.innerHTML = "0" + (e.slides.length - 2)
          }
        },
        slideChange: function (e) {
          if (e.realIndex + 1 >= 10) {
            sliderCount.innerHTML = e.realIndex + 1
          } else {
            sliderCount.innerHTML = "0" + (e.realIndex + 1)
          }
          if (e.slides.length >= 10) {
            sliderCountLength.innerHTML = e.slides.length
          } else {
            sliderCountLength.innerHTML = "0" + (e.slides.length - 2)
          }
        },
      },
    })

    var seperatorSliderThumb = new Swiper(".js-seperator-text-slider", {
      speed: 800,
      loop: true,
      grabCursor: true,
      modules: [Thumbs, Navigation, Autoplay],
      autoplay: {
        delay: 2500,
        pauseOnMouseEnter: true,
        disableOnInteraction: false,
      },
      thumbs: {
        swiper: seperatorSlider,
      },
      navigation: {
        nextEl: ".js-seperator-slider-next",
        prevEl: ".js-seperator-slider-prev",
      },
    })
  },
  gallerySliderFn() {
    const gallerySwiper = new Swiper(".js-gallery-slider", {
      speed: 800,
      loop: true,
      slidesPerView: 2.4,
      spaceBetween: 40,
      centeredSlides: true,
      centeredSlidesBounds: true,
      modules: [Pagination],

      pagination: {
        el: ".js-gallery-slider .swiper-pagination",
        clickable: true,
        type: "bullets",
      },
      breakpoints: {
        320: {
          slidesPerView: 1.2,
          spaceBetween: 15,
        },
        769: {
          slidesPerView: 1.6,
          spaceBetween: 20,
        },
        993: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        1201: {
          slidesPerView: 2.4,
          spaceBetween: 40,
        },
      },
    })
  },
  countUpFn() {
    var countTupVal = $(".js-count-item")

    countTupVal.each(function (index) {
      var value = $(countTupVal[index]).html()
      var countUpV = new CountUp(countTupVal[index], value, {
        enableScrollSpy: true,
        scrollSpyDelay: 100,
        scrollSpyOnce: true,
        duration: 5,
        useEasing: true,
        suffix: countTupVal[index].getAttribute("data-suffix"),
      })
      countUpV.handleScroll()
    })
  },
  flagSliderFn() {
    const flagSwiper = new Swiper(".js-flag-slider", {
      loop: true,
      slidesPerView: 13,
      spaceBetween: 40,
      speed: 700,
      centeredSlides: true,
      centeredSlidesBounds: true,

      modules: [Autoplay],
      autoplay: {
        delay: 2500,
      },
      breakpoints: {
        320: {
          slidesPerView: 5,
          spaceBetween: 20,
        },
        577: {
          slidesPerView: 6,
          spaceBetween: 20,
        },
        769: {
          slidesPerView: 7,
          spaceBetween: 20,
        },
        993: {
          slidesPerView: 10,
        },
        1201: {
          slidesPerView: 13,
        },
      },
    })
  },

  detailPageFn() {
    const detailPage = document.querySelector(".detail-page")
    if (detailPage) {
      document.querySelector(".header").classList.add("header--dark")
    }
  },

  countUpFn() {
    var countTupVal = $(".js-count-item")

    countTupVal.each(function (index) {
      var value = $(countTupVal[index]).html()
      var countUpV = new CountUp(countTupVal[index], value, {
        enableScrollSpy: true,
        scrollSpyDelay: 100,
        scrollSpyOnce: true,
        duration: 5,
        useEasing: true,
        suffix: countTupVal[index].getAttribute("data-suffix"),
      })
      countUpV.handleScroll()
    })
  },

  historyItemsFn() {
    const items = document.querySelectorAll(".about-history__item")
    items.forEach((item, i) => {
      let text = item.getAttribute("data-history-text")
      let year = item.querySelector(".about-history__year").innerHTML
      item.addEventListener("mouseenter", () => {
        document.querySelector(".js-history-text").innerHTML = text
        document.querySelector(".js-history-year").innerHTML = year
        document
          .querySelectorAll(".about-history__item.active")
          .forEach((itemActive) => {
            itemActive.classList.remove("active")
          })
        item.classList.add("active")
      })
      if (items.length <= 10) {
        if (i === items.length - 2 || i === items.length - 1) {
          item.style.marginTop = "-5rem"
        }
      }
      if (items.length <= 9) {
        if (i === items.length - 2) {
          item.style.marginTop = "-0rem"
        }
      }
      if (items.length <= 8) {
        if (i === items.length - 2 || i === items.length - 1) {
          item.style.marginTop = ""
        }
      }
      if (items.length <= 7) {
        if (i === items.length - 1) {
          item.style.marginTop = "0rem"
        }
      }
      if (items.length <= 6) {
        if (i === items.length - 2 || i === items.length - 1) {
          item.style.marginTop = "-3rem"
        }
      }
      if (items.length <= 5) {
        if (i === items.length - 2) {
          item.style.marginTop = ""
        }
      }
      if (items.length <= 4) {
        if (i === items.length - 1) {
          item.style.marginTop = ""
        }
      }
      if (items.length <= 3) {
        if (i === items.length - 1) {
          item.style.marginTop = "5rem"
        }
      }
    })
  },
  popupFunction() {
    const closePopupBtn = document.querySelectorAll(".js-popup-close")
    const popup = document.querySelector(".index-popup")
    if (popup) {
      // Check if the user has already seen the popup in the last week.
      const cookie = getCookie("popup")
      if (!cookie) {
        // Show the popup.
        popup.classList.remove("hidden")
        // Set a cookie to prevent the popup from showing again for 1 week.
        closePopupBtn.forEach((button) => {
          button.addEventListener("click", (e) => {
            popup.classList.add("hidden")
            setCookie("popup", "1", 7 * 24 * 60 * 60) // 1 week in seconds
          })
        })
      }

      function getCookie(name) {
        const cookieValue = document.cookie.match(
          `(^|;)\\s*${name}\\s*=\\s*([^;]+)`
        )
        return cookieValue ? cookieValue.pop() : null
      }

      function setCookie(name, value, seconds) {
        const expires = new Date(Date.now() + seconds * 1000).toUTCString()
        document.cookie = `${name}=${value}; expires=${expires}; path=/`
      }
    }
  },

  init: function () {
    app.load()
    app.smoothScrolling()
    app.preloaderAnimation()
    app.headRoomFn()
    app.headerLanguageFn()
    app.searchButtonFn()
    app.mobileMenuFn()
    app.heroSwiper()
    app.heroCategorySwiper()
    app.fieldsSwiper()
    app.seperatorSwiper()
    app.detailPageFn()
    app.accordionFn()
    app.gallerySliderFn()
    app.countUpFn()
    app.historyItemsFn()
    app.flagSliderFn()
    app.popupFunction()
  },
}

function docReadied(fn) {
  window.addEventListener("DOMContentLoaded", fn)
}
function docResized(fn) {
  window.addEventListener("resize", fn)
}
docReadied(() => {
  app.init()
})

docResized(() => {
  app.resized()
})

export default { docResized, docReadied }
